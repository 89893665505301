import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Slide, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/system';

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  color: '#D00090',
  textAlign: 'center',
  fontWeight: 'bold',
  padding: theme.spacing(2),
  position: 'relative',
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: '#2c2c2c',
  color: '#ffffff',
  padding: theme.spacing(3),
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  padding: theme.spacing(2),
  justifyContent: 'center',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: '10px 30px',
  fontWeight: 'bold',
  backgroundColor: '#D00090',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ad006f',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  marginTop: theme.spacing(2),
  backgroundColor: '#D00090',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ad006f',
  },
}));

const CustomModal = ({ open, handleClose }) => {
  const [formStatus, setFormStatus] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/xjkbzbgw', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      });

      if (response.ok) {
        setFormStatus('success');
        form.reset();
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      setFormStatus('error');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      PaperProps={{
        style: {
          borderRadius: 15,
          maxWidth: 600, // Increased the size of the modal
          margin: '0 auto',
        },
      }}
    >
      <CustomDialogTitle id="custom-modal-title">
        Get in Touch
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#D00090',
          }}
        >
          <Close />
        </IconButton>
      </CustomDialogTitle>
      <CustomDialogContent>
        <Typography variant="body1" id="custom-modal-description" sx={{ textAlign: 'center', marginBottom: 3 }}>
          We appreciate your interest in DeepKraken. We're currently preparing for our official launch. Stay tuned for our announcement on the launch date. In the meantime, feel free to reach out to us using the form below, and we'll keep you updated!
        </Typography>

        {formStatus === 'success' ? (
          <Typography variant="h6" sx={{ textAlign: 'center', color: '#4caf50', marginBottom: 3 }}>
            Thank you! Your message has been sent successfully.
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Your Name"
              name="name"
              variant="outlined"
              fullWidth
              required
              margin="dense"
              InputLabelProps={{ style: { color: '#ffffff' } }}
              InputProps={{ style: { color: '#ffffff', borderColor: '#D00090' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D00090',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ad006f',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D00090',
                  },
                },
              }}
            />
            <TextField
              label="Your Email"
              name="_replyto"
              type="email"
              variant="outlined"
              fullWidth
              required
              margin="dense"
              InputLabelProps={{ style: { color: '#ffffff' } }}
              InputProps={{ style: { color: '#ffffff', borderColor: '#D00090' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D00090',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ad006f',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D00090',
                  },
                },
              }}
            />
            <TextField
              label="Your Message"
              name="message"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              margin="dense"
              InputLabelProps={{ style: { color: '#ffffff' } }}
              InputProps={{ style: { color: '#ffffff', borderColor: '#D00090' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D00090',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ad006f',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D00090',
                  },
                },
              }}
            />
            <FormButton type="submit" variant="contained" fullWidth>
              Send Message
            </FormButton>
          </form>
        )}

        {formStatus === 'error' && (
          <Typography variant="h6" sx={{ textAlign: 'center', color: '#f44336', marginTop: 2 }}>
            Oops! Something went wrong. Please try again.
          </Typography>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton onClick={handleClose}>Close</CustomButton>
      </CustomDialogActions>
    </Dialog>
  );
};

export default CustomModal;
