import React, { useState } from 'react';
import { Box, Typography, Button, Container, Stack, Fade, Zoom, Slide } from '@mui/material';
import { styled } from '@mui/system';
import CustomModal from './CustomModal';  // Import the modal component

const backgroundImage = `${process.env.PUBLIC_URL}/background.webp`;

const GradientBackground = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '100vh',
  background: `radial-gradient(circle, rgba(208, 0, 144, 0.8) 0%, rgba(36, 36, 36, 1) 70%), url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  color: '#ffffff',
  padding: '0 20px',
  opacity: 0.9,  // Adjust opacity for transparency effect
  animation: 'gradientShift 10s ease infinite',
  '@keyframes gradientShift': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
});

const PulseButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: '10px 30px',
  borderRadius: '50px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  boxShadow: '0px 0px 20px rgba(208, 0, 144, 0.7)', // Adjusted glow to match logo colors
  transition: 'all 0.4s ease',
  '&:hover': {
    boxShadow: '0px 0px 30px rgba(208, 0, 144, 1)',
    transform: 'scale(1.05)',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    borderRadius: '50px',
    top: '-50%',
    left: '-50%',
    height: '200%',
    width: '200%',
    backgroundColor: 'rgba(208, 0, 144, 0.5)', // Adjusted glow to match logo colors
    zIndex: 0,
    opacity: 0,
    transition: 'opacity 0.6s ease, transform 0.6s ease',
    transform: 'scale(0.8)',
  },
  '&:hover:before': {
    opacity: 1,
    transform: 'scale(1)',
  },
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 800,
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  animation: 'textGlow 2s ease-in-out infinite alternate',
  '@keyframes textGlow': {
    '0%': {
      textShadow: '0 0 10px rgba(208, 0, 144, 0.8), 0 0 20px rgba(208, 0, 144, 0.6), 0 0 30px rgba(208, 0, 144, 0.4)',
    },
    '100%': {
      textShadow: '0 0 20px rgba(208, 0, 144, 1), 0 0 30px rgba(208, 0, 144, 0.8), 0 0 40px rgba(208, 0, 144, 0.6)',
    },
  },
}));

function Home() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GradientBackground>
      <Container maxWidth="md">
        {/* Logo at the top center */}
        <Box sx={{ mb: 4 }}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="DeepKraken Logo" style={{ maxWidth: '150px' }} />
        </Box>

        {/* Main Content */}
        <Zoom in style={{ transitionDelay: '100ms' }}>
          <AnimatedTypography variant="h2" gutterBottom>
            DeepKraken
          </AnimatedTypography>
        </Zoom>
        <Slide direction="up" in style={{ transitionDelay: '500ms' }}>
          <Typography variant="h5" sx={{ mb: 4, fontWeight: 300, lineHeight: 1.5 }}>
            Empower your intelligence and fraud investigations with real-time insights from the dark web.
          </Typography>
        </Slide>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <PulseButton variant="contained" color="primary" onClick={handleClickOpen}>
            Learn More
          </PulseButton>
          <PulseButton variant="outlined" color="primary" onClick={handleClickOpen}>
            Get Started
          </PulseButton>
        </Stack>
      </Container>

      {/* Use the Custom Modal */}
      <CustomModal open={open} handleClose={handleClose} />
    </GradientBackground>
  );
}

export default Home;
