import React from 'react';
import { Box, Typography, IconButton, Stack } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Google } from '@mui/icons-material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.background.paper,
        textAlign: 'center',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)', // subtle top border for separation
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Hive Forensics A.I. Inc.
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        &copy; 2024 All Rights Reserved
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <IconButton color="primary" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <Facebook sx={{ '&:hover': { color: '#3b5998' } }} />
        </IconButton>
        <IconButton color="primary" href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <Twitter sx={{ '&:hover': { color: '#1DA1F2' } }} />
        </IconButton>
        <IconButton color="primary" href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <LinkedIn sx={{ '&:hover': { color: '#0077B5' } }} />
        </IconButton>
        <IconButton color="primary" href="https://plus.google.com" target="_blank" rel="noopener noreferrer">
          <Google sx={{ '&:hover': { color: '#DB4437' } }} />
        </IconButton>
      </Stack>
    </Box>
  );
}

export default Footer;
