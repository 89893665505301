import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box, Container } from '@mui/material';
import Home from './components/Home';
import Footer from './components/Footer';
import { styled } from '@mui/system';

// Background image path
const backgroundImage = `${process.env.PUBLIC_URL}/background.webp`;

// Create a gradient background that applies to the entire site with visibility effects
const GradientBackground = styled(Box)({
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  background: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.97)', // Very strong black overlay
    zIndex: 1,
    animation: 'backgroundBreath 10s ease-in-out infinite',
  },
  '@keyframes backgroundBreath': {
    '0%': {
      opacity: 0.97,  // Nearly invisible
    },
    '50%': {
      opacity: 0.92,  // Barely more visible
    },
    '100%': {
      opacity: 0.97,  // Back to nearly invisible
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D00090', // Updated to match the Kraken logo's purple/pink color
    },
    background: {
      default: 'transparent', // This will allow the gradient background to show through
      paper: 'transparent',  // Ensures containers are also transparent
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h2: {
      fontWeight: 800,
      textTransform: 'uppercase',
      letterSpacing: '0.15em',
    },
    body1: {
      fontWeight: 300,
      color: 'rgba(255, 255, 255, 0.7)', // Slightly muted text for body content
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px', // Rounded buttons for a sleek look
          padding: '12px 36px',
          fontWeight: 'bold',
          transition: 'all 0.4s ease',
          '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0px 0px 25px rgba(208, 0, 144, 0.8)', // Updated shadow to match logo colors
          },
        },
        containedPrimary: {
          backgroundColor: '#D00090', // Updated to match logo colors
          '&:hover': {
            backgroundColor: '#AD006F',
          },
        },
        outlinedPrimary: {
          borderColor: '#D00090',
          color: '#D00090',
          '&:hover': {
            borderColor: '#AD006F',
            color: '#AD006F',
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GradientBackground>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative', zIndex: 2 }}>
          <Container component="main" sx={{ flex: 1 }}>
            <Home />
          </Container>
          <Footer />
        </Box>
      </GradientBackground>
    </ThemeProvider>
  );
}

export default App;
